import { ReactElement, useState } from "react";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";
import DataTables, { Data } from "../../DataTables";
import { FoxIcon, InputIcon } from "../../../assets";
import { SortOrder, TableColumn, TableStyles } from "react-data-table-component";
import { sortCaseHandler } from "../../../helper";
import { useAppDispatch } from "../../../store/hooks";
import { setSort } from "../../../store/reducers/commonSlice";
import copy from "copy-to-clipboard";
import CopyToClipboard from "../../CopyToClipboard";

interface IRow {
  id: string;
  item1: string;
  item2: string;
  item3: string;
}

type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable?: boolean;
  style?: {};
  cell?: (row: any) => string | ReactElement;
  sortField?: string;
  width?: string;
};

const copyToClipboard = (val?: string) => {
  if (val) copy(val);
};

function renderDepositor(rowItem: string) {
  if (!rowItem) return "-";
  const truncatedRowItem = `${rowItem.slice(0, 6)}...${rowItem.slice(10, 12)}`;
  return (
    <div style={{
      paddingTop: "12px",
      paddingBottom: "12px",
      width: "100%",
    }}>
      <CopyToClipboard value={rowItem} isDepositor = {true}/>
    </div>
  );
}

const contentStyle = {
  fontWeight: 600,
  fontSize: 14,
  color: "#190044",
};

const firstColumnStyle = {
  fontWeight: 600,
  fontSize: 15,
  color: "#525885",
};


const columns: Columns[] = [
  {
    name: "Depositor",
    selector: (row) => row.id,
    style: firstColumnStyle,
    sortable: true,
    cell: (row: IRow) => renderDepositor(row.id),
    sortField: "depositor"
  },
  {
    name: "Since",
    selector: (row) => row.item1,
    style: contentStyle,
    sortable: true,
    sortField: "since",
  },
  {
    name: "Number of shares",
    selector: (row) => row.item2,
    style: contentStyle,
    sortable: true,
    sortField: "shares"
  },
  {
    name: "Percentage",
    selector: (row) => row.item3,
    style: contentStyle,
    sortable: true,
    sortField: "percentage"
  },
];
const dummyData = [
  {
    id: "0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e",
    item1: "6 months",
    item2: "91.2592",
    item3: "18.32%",

  }
  ,
  {
    id: "0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e",
    item1: "6 months",
    item2: "91.2592",
    item3: "18.32%",

  },
  {
    id: "0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e",
    item1: "6 months",
    item2: "91.2592",
    item3: "18.32%",

  },
  {
    id: "0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e",
    item1: "6 months",
    item2: "91.2592",
    item3: "18.32%",

  },
  {
    id: "0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e",
    item1: "6 months",
    item2: "91.2592",
    item3: "18.32%",

  },
];

const customStyles: TableStyles = {
  rows: {
    style: {
      '&:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
      },
      border: "0px !important",
      BorderBottom: "none",
      boxShadow: "none",
      borderRadius: "6px",
      height: "auto",
    },
  },
  headCells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "44.3px",
      PaddingTop: "12px",
      PaddingBottom: "13px",
      background: "white",
      height: "48px",
      borderBottom: 0,
      fontWeight: 700,
      fontSize: 15,
      color: "#9599B5",
    },
  },
  cells: {
    style: {
      paddingLeft: "12px",
      paddingRight: "12px",
      borderBottom: 0,
      fontWeight: 400,
      fontSize: 15,
      color: "#525885",
      height: "auto",
    },
  },
};

const Depositors = () => {
  const dispatch = useAppDispatch();
  const [sortValue, setSortValue] = useState("all");
  
  const handleSort = async (
    selectedColumn: TableColumn<Data>,
    sortDirection: SortOrder,
    sortedRows: Data[]
  ) => {
    const sort = sortingFunction(selectedColumn?.sortField, sortDirection);
    dispatch(setSort({ sort: sort ?? 0 }));
  };
  const sortingFunction = (
    sortField: string | undefined,
    sortDirection: string
  ) => {
    switch (sortField) {
      case 'depositor':
        return sortCaseHandler(sortDirection, 1, 2)
      case 'since':
        return sortCaseHandler(sortDirection, 5, 6)
      case 'share':
        return sortCaseHandler(sortDirection, 7, 8)
      case 'percentage':
        return sortCaseHandler(sortDirection, 9, 10)
    }
  };

  return (
    <>
      <h2 className={styles.title}>Depositors</h2>
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.depositorContainer}>
            <div className={styles.depositorTitle}>
              <h2>Depositors Overview</h2>
            </div>
            <DataTables count={dummyData.length} pagination handleSort={handleSort} customStyles={customStyles} selectableRows={false} columns={columns} data={dummyData} />
          </div>
        </FlexBox>
      </div>
    </>
  )

};

export default Depositors;