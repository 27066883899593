import { useEffect, useState } from "react";
import { FoxIcon, InputIcon, CircularTick } from "../../assets";
import styles from './index.module.scss'
import copy from "copy-to-clipboard";
import { Tooltip } from "@mui/material";

export default function CopyToClipboard({ value, isPolicies, isDepositor, isFees, isActivity }: { value: string, isPolicies?: boolean, isDepositor?: boolean, isFees?: boolean, isActivity?: boolean }) {
  const [copySuccess, setCopySuccess] = useState<boolean>(false)
  const getStyle = () => {
    if (isPolicies) return styles.managerAddressPolicy;
    if (isActivity) return styles.activityAddress;
    return styles.managerAddress;
  };
  const copyVal = (val: string) => {
    if (val) { copy(val); setCopySuccess(true) }
  };

  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false)
    }, 2000);
  }, [copySuccess])
  return (
    <>
      <div className={getStyle()}>
        {!isActivity && <div className={styles.imageContainer}>
          <div className={styles.managerImage}>
            <FoxIcon />
          </div>
        </div>}
        <Tooltip
          classes={{ tooltip: styles.priceTooltip, arrow: styles.arrow }}
          title={value}
          arrow
          placement="top">
          <div className={styles.address}>{(isPolicies || isDepositor || isFees || isActivity) ? value.slice(0, 3) + "....." + value.slice(10, 12) : value}</div>
        </Tooltip>
        <div className={styles.copyBtn}>{copySuccess ? <CircularTick stroke="#4e307a" /> : <InputIcon onClick={() => copyVal(value)} />}</div>
      </div>
    </>
  )
}
