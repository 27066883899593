import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import Title from "../../../components/Title";
import CustomModal from "../../../components/common/CustomModal";
import styles from "./index.module.scss";
import PopupTitle from "../../../components/PopupTitle";
import FlexBox from "../../../components/common/FlexBox";
import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../../components/Input";
import { useAppSelector } from "../../../store/hooks";
import { Data } from "../../../components/DataTables";
import { useNavigate } from "react-router-dom";
import { useAlertMessage } from "../../../context/AlertContext";

type Props = {
    isDeleteOpen?: boolean | undefined;
    isDeleted?: boolean;
    setIsDeleted: Dispatch<SetStateAction<boolean>>;
    isEditOpen?: boolean;
    isEdited?: boolean;
    isAddOpen: boolean;
    setIsEdited?: Dispatch<SetStateAction<boolean>>;
    toggleAll: () => void;
    selectedItems?: Data | undefined;
    resetHandler: () => void;
    type: "user" | "profile";

};
const editFeeSchema = yup.object({
    feeRate: yup
      .number()
      .typeError("feeRate must be a number")
      .min(0)
      .required("feeRate is required"),
    recipientAddress: yup.string().optional()
  });

  const addFeeSchema = yup.object({
    feeType: yup.string().optional(),
    setting1Name: yup.string().required("setting 1 Name is required"),
    setting1Value: yup.string().required("setting 1 Number is required"),
    setting2Name: yup.string().optional(),
    setting2Value: yup.string().optional(),
  });
const FeeModals = (props: Props) => {
    const {
        isDeleteOpen,
        isDeleted,
        setIsDeleted,
        isEditOpen,
        isEdited,
        setIsEdited,
        isAddOpen,
        toggleAll,
        selectedItems,
        resetHandler,
        type
    } = props;
    const { setAlertMsg } = useAlertMessage();

    const methods = useForm({
        defaultValues: {
            feeRate: 0,
            recipientAddress: "",
        },
        resolver: yupResolver(editFeeSchema),
    });
    const addFormMethods = useForm({
        defaultValues: {
            feeType: "",
            setting1Name: "",
            setting1Value: "",
            setting2Name: "",
            setting2Value: "",
        },
        resolver: yupResolver(addFeeSchema),
    });
    const { isEditable, user } = useAppSelector(
        (state) => state.userManage
    );
    
    const navigate = useNavigate();
    useEffect(() => {
        if (isEditable && user) {
        }
    }, [isEditable, user]);
    const {
        feeRate,
        recipientAddress,
    } = methods.formState.errors;

    const {
        feeType,
        setting1Name,
        setting1Value,
        setting2Name,
        setting2Value,
    } = addFormMethods.formState.errors;

    const handelUpdateUser = async (event: any) => { };

    const editFeeHandler = async () => {
        const {
            feeRate,
            recipientAddress
        } = methods.getValues();

        const payload = {
            feeRate,
            recipientAddress
        };
        console.log("payload", payload)

        setAlertMsg?.({
            type: "success",
            msg: "Fee type edited successfully!"
        });
        toggleAll();
    };
    const deleteFeerHandler = async () => {
        setAlertMsg?.({
            type: "success",
            msg: "Fee type deleted successfully!"
        });
        setIsDeleted(true);
        resetHandler()


    };
    const isDeletedHandler = () => {
        if (type === "user") {
            toggleAll();
        } else {
            toggleAll();
            navigate(-1);
        }
    };


    return (
        <div>
            <CustomModal isOpen={isDeleteOpen ?? false} toggle={toggleAll}>
                <Title
                    title={`Delete Fee type!`}
                    content="Are you sure you want to delete this Fee Type?"
                    className={styles.content}
                />

                <div className={styles.btnContainer}>
                    <CustomButton variant="outlined" onClick={toggleAll}>
                        Cancel
                    </CustomButton>
                    <CustomButton
                        variant="primary"

                        onClick={deleteFeerHandler}
                    >
                        Delete
                    </CustomButton>
                </div>
            </CustomModal>
            <CustomModal isOpen={isDeleted ?? false} toggle={toggleAll}>
                <Title
                    title={`Fee Type deleted`}
                    content="This Fee Type has been deleted successfully!"
                    className={styles.content}
                />

                <div className={styles.btnContainer}>
                    <CustomButton variant="primary" onClick={isDeletedHandler}>
                        Ok, Thanks!
                    </CustomButton>
                </div>
            </CustomModal>

            <CustomModal
                isOpen={isEditOpen ?? false}
                toggle={toggleAll}
                classNames={{ modal: styles.editModalContainer }}
            >
                <PopupTitle title="Edit fee" />
                <FlexBox className={styles.flex}>
                    <div className={styles.flexItem}>
                        <h4>Fee type</h4>
                        <div className={styles.feeName}>{selectedItems?.id}</div>
                    </div>

                </FlexBox>
                <form onSubmit={methods.handleSubmit(handelUpdateUser)}>
                    <FlexBox className={styles.modalFlex}>
                        <Controller
                            control={methods.control}
                            name="feeRate"
                            render={({ field }) => (
                                <Input
                                    type="number"
                                    placeholder="1%"
                                    id="feeRate"
                                    label="Fee Rate"
                                    error={feeRate as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    </FlexBox>
                    <FlexBox className={styles.modalFlex}>
                        <Controller
                            control={methods.control}
                            name="recipientAddress"
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    placeholder="0x36756db0feb879f2f9ade7df3006df5c2dbaf5de"
                                    id="recipientAddress"
                                    label="Recipient Address (optional)"
                                    error={recipientAddress as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    </FlexBox>
                    <FlexBox className={styles.editBtnContainer}>
                        <CustomButton variant="outlined" onClick={toggleAll}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            onClick={
                                editFeeHandler
                                //     {
                                //     toggleAll();
                                //     setIsEdited(true);
                                // }
                            }
                        >
                            Save changes
                        </CustomButton>
                    </FlexBox>
                </form>
            </CustomModal>

            <CustomModal
                isOpen={isAddOpen ?? false}
                toggle={toggleAll}
                classNames={{ modal: styles.editModalContainer }}
            >
            <form onSubmit={addFormMethods.handleSubmit(handelUpdateUser)}>
                <PopupTitle title="Add fee" />
                <FlexBox className={styles.flex}>
                    <div className={styles.flexItem}>
                        <h4>Set type</h4>
                        <FlexBox className={styles.modalFlex}>
                        <Controller
                            control={addFormMethods.control}
                            name="feeType"
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    placeholder="1%"
                                    id="feeType"
                                    label="Fee Type"
                                    error={feeType as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    </FlexBox>
                    </div>         
                </FlexBox>
                <h4 className={styles.settingType}>Fee Settings</h4>  
                    <FlexBox className={styles.modalFlex}>
                        <Controller
                            control={addFormMethods.control}
                            name="setting1Name"
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    placeholder="1%"
                                    id="setting1Name"
                                    label="setting 1 Name"
                                    error={setting1Name as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                        <Controller
                            control={addFormMethods.control}
                            name="setting1Value"
                            render={({ field }) => (
                                <Input
                                    type="number"
                                    placeholder="1%"
                                    id="setting1Value"
                                    label="Number"
                                    error={setting1Value as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    </FlexBox>
                    <hr style={{ border: 'none', borderTop: '1px dashed #DDDEED', marginBottom: "10px"}} />
                    <FlexBox className={styles.modalFlex}>
                    <Controller
                            control={addFormMethods.control}
                            name="setting2Name"
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    placeholder="1%"
                                    id="setting2Name"
                                    label="setting 2 Name"
                                    error={setting2Name as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    <Controller
                            control={addFormMethods.control}
                            name="setting2Value"
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    placeholder="1%"
                                    id="setting2Value"
                                    label="Number"
                                    error={setting2Value as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                    </FlexBox>
                    <FlexBox className={styles.editBtnContainer}>
                        <CustomButton variant="outlined" onClick={toggleAll}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            onClick={
                                editFeeHandler
                                //     {
                                //     toggleAll();
                                //     setIsEdited(true);
                                // }
                            }
                        >
                            Save changes
                        </CustomButton>
                    </FlexBox>
                </form>
            </CustomModal>
            

            <CustomModal isOpen={isEdited ?? false} toggle={toggleAll}>
                <Title
                    title="Changes saved"
                    content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    className={styles.content}
                />

                <div className={styles.btnContainer}>
                    <CustomButton variant="primary" onClick={toggleAll}>
                        Ok, Thanks!
                    </CustomButton>
                </div>
            </CustomModal>
        </div>
    );
};

export default FeeModals;
