type Option = {
  label: string;
  value: string;
};

export const findSelectOption = (arr: Option[], value: string | undefined) => {
  const getObj = arr.find((item) => item.value === value);
  return getObj;
};

export const sortCaseHandler = (direction: string, asc: number, des: number) => {
  if (direction === "asc") {
    return asc;
  } else {
    return des;
  }
}

export const getTokenNameBySymbol = (symbol: string) => {
  switch (symbol) {
    case "ada":
    case "ADA":
      return "Cardano Token";
    case "jton":
    case "JTON":
      return "Wrapped TON Coin";
    case "doge":
    case "DOGE":
      return "DogeCoin";
    case "xrp":
    case "XRP":
      return "XRP Token";
    case "usdc":
    case "USDC":
      return "USD Coin";
    case "sol":
    case "SOL":
      return "Solana";
    case "bnb":
    case "BNB":
      return "BNB";
    case "usdt":
    case "USDT":
      return "Tether USD";
    case "eth":
    case "ETH":
      return "Ethereum";
    case "btc":
    case "BTC":
      return "Bitcoin";
    default:
      return "Token";
  }
};
