import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { TableStyles } from "react-data-table-component";
import useParentWidth from "../../../Hooks/useParentWidth";
import variable from "../../../styles/variables.module.scss";
import { formatAmountToFixed } from "../../../helper/helper";
import Card from "../../Card";
import DataTables from "../../DataTables";
import FinancialChart from "../../FinanceChart";
import IndicatorTokenCard from "../../IndicatorTokenCard/IndicatorTokenCard";
import ReactSelect, { CustomStyles } from "../../Select";
import SortByYear from "../../SortByYear";
import Spinner from "../../Spinner";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";
import CopyToClipboard from "../../CopyToClipboard";

interface IRow {
  id: number;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
  item6: string;
  item7: string;
  item8: string;
  item9: string;
  item10: string;
  item11: string;
  item12: string;
}
type Columns = {
  name: string;
  selector: (row: any) => string;
  sortable?: boolean;
  style?: {};
  cell?: any
};

const definedStyle: CustomStyles | undefined = {
  option: (provided, state) => {
    return {
      ...provided,

      backgroundColor: state.isSelected ? variable["text-dark"] : null,
      background: state.isFocused
        ? variable["select-bg"]
        : state.isSelected
          ? variable["text-dark"]
          : null,
      cursor: "pointer",
      "&:active": {
        backgroundColor: variable["select-bg"],
      },
    };
  },
  control: (base, state) => ({
    ...base,
    height: 32,
    boxShadow: state.isFocused ? 0 : 0,
    background: "#fafafa !important",
    borderRadius: 12,
    border: `1px solid #DDDEED`,
    fontWeight: 600,
    fontSize: 15,
    color: "red",
    cursor: "pointer",
    borderColor: state.isFocused
      ? variable["input-border"]
      : variable["input-border"],
    "&:hover": {
      borderColor: state.isFocused
        ? variable["input-border"]
        : variable["input-border"],
    },
    textAlign: "left",
  }),
  singleValue: (base, props) => ({
    ...base,
    color: variable["text-dark"]
  }),
  dropdownIndicator: (base, props) => ({
    ...base,
    color: variable['text-dark']
  })
}

function renderCell(rowItem: string) {
  let style;
  let displayValue;

  if (rowItem) {
    if (rowItem.startsWith('+')) {
      style = contentPositiveNumber;
    } else if (rowItem.startsWith('-')) {
      style = contentNegativeNumber;
    }
    displayValue = rowItem;
  } else {
    style = dashStyle;
    displayValue = '-';
  }

  return <div style={style}>{displayValue}</div>;
}

const contentStyle = {
  fontWeight: 700,
  fontSize: 15,
  color: "#9599B5",
};
const dashStyle = {
  fontWeight: 700,
  fontSize: 15,
  color: "#9599B5",
};

const firstColumnStyle = {
  fontWeight: 600,
  fontSize: 15,
  color: "#525885",
};

const contentPositiveNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#3E3AFF",
};
const contentNegativeNumber = {
  fontWeight: 700,
  fontSize: "15px",
  letterSpacing: "0.02em",
  color: "#F97172",
};

const columns: Columns[] = [
  {
    name: "Year",
    selector: (row) => row.id,
    style: firstColumnStyle,
  },
  {
    name: "Jan",
    selector: (row) => row.item1,
    style: contentStyle,
    cell: (row: IRow) => renderCell(row.item1),
  },
  {
    name: "Feb",
    selector: (row) => row.item2,
    style: contentStyle,
    cell: (row: IRow) => renderCell(row.item2),
  },
  {
    name: "Mar",
    selector: (row) => row.item3,
    style: contentStyle,
    cell: (row: IRow) => renderCell(row.item3),
  },
  {
    name: "Apr",
    selector: (row) => row.item4,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item4),
  },
  {
    name: "May",
    selector: (row) => row.item5,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item5),
  },
  {
    name: "Jun",
    selector: (row) => row.item6,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item6),
  },
  {
    name: "Jul",
    selector: (row) => row.item7,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item7),
  },
  {
    name: "Aug",
    selector: (row) => row.item8,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item8),
  },
  {
    name: "Sep",
    selector: (row) => row.item9,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item9),
  },
  {
    name: "Oct",
    selector: (row) => row.item10,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item10),
  },
  {
    name: "nov",
    selector: (row) => row.item11,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item11),
  },
  {
    name: "Dec",
    selector: (row) => row.item12,
    style: contentPositiveNumber,
    cell: (row: IRow) => renderCell(row.item12),
  },
];
const dummyData = [
  {
    id: 2020,
    item1: "+0.15%",
  },
  {
    id: 2201,
    item1: "+0.15%",
    item6: "+0.15%",

  },
  {
    id: 2022,
    item1: "+0.15%",
    item6: "-0.15%",
  },
  {
    id: 2023,
    item3: "+0.15%",
    item4: "-2.14",
  },
  {
    id: 2024,
    item1: "+0.15%",

  },
];
const options = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "share_price",
    label: "Share Price",
  },
  {
    value: "7",
    label: "1W",
  },
  {
    value: "30",
    label: "1M",
  },
  {
    value: "365",
    label: "1Y",
  }
]

const data = {
  "labels": [
    "May 23",
    "Jun 23",
    "Jul 23",
    "Aug 23",
    "Sep 23",
    "Oct 23",
    "Nov 23",
    "Dec 23",
    "Jan 24",
    "Feb 24",
    "Mar 24",
    "Apr 24",
    "May 24"
  ],
  "datasets": [
    {
      "label": "Portfolio value",
      "data": [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        29
      ],
      "fill": false,
      "borderColor": "rgb(78, 48, 122)",
      "pointStyle": "circle",
      "pointRadius": 10,
      "pointHoverRadius": 15
    }
  ]
}
const Overview = ({ denominationAsset }: { denominationAsset: string[] }) => {
  const [selectedOption, setSelectedOption] = useState(options[1]);
  const [sortValue, setSortValue] = useState("all");
  const { parentRef, parentWidth } = useParentWidth();
  const [copySuccessOne, setCopySuccessOne] = useState<boolean>(true)
  const [copySuccessTwo, setCopySuccessTwo] = useState<boolean>(false)
  const handleSortValue = (val: string) => {
    if (false) return;
    setSortValue(val);
  };
  const copyToClipboard = (val: string, type: number) => {
    if (val) {copy(val); type == 0?setCopySuccessOne(true): setCopySuccessTwo(false)}
  };
  const customStyles: TableStyles = {
    rows: {
      style: {
        '&:nth-child(odd)': {
          backgroundColor: '#F9F9F9',
        },
        border: "0px !important",
        BorderBottom: "none",
        boxShadow: "none",
        borderRadius: "6px",
        height: "48px",
        '& > *': {
          minWidth: '0px !imortant',
        },
        width: "1313px"
      },
    },
    headCells: {
      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        PaddingTop: "12px",
        PaddingBottom: "13px",
        background: "white",
        height: "48px",
        borderBottom: 0,
        fontWeight: 700,
        fontSize: 15,
        color: "#9599B5",
      },
    },

    cells: {
      style: {
        paddingLeft: "0px",
        paddingRight: "0px",
        PaddingTop: "14px",
        PaddingBottom: "13px",
        borderBottom: 0,
        fontWeight: 400,
        fontSize: 15,
        color: "#525885",
        height: "48px",
        minWidth: "unset !important",
      },
    },
    table: {
      style: {
        width: "990px"
      },
    },
  };
  useEffect(()=>{setTimeout(() => {
    setCopySuccessOne(false)
    setCopySuccessTwo(false)
  }, 2000);}, [copySuccessOne, copySuccessTwo])
  return (
    <>
      <h2 className={styles.title}>Overview</h2>
      <FlexBox className={styles.flexTokenCard}>
        <IndicatorTokenCard
          key={1}
          valueClassName={styles.valueClassName}
          heading="Assets Under Management"
          val={"$" + 1391}

        />
        <IndicatorTokenCard
          key={1}

          heading="Depositors"
          val={6860}
          dollar
        />
        <IndicatorTokenCard
          key={1}
          valueClassName={styles.avgMonthlyValue}
          heading="Average monthly deposits"
          val={"+2.50%"}
        />
        <IndicatorTokenCard
          key={1}
          heading="Denomination Asset"
          valueClassName={styles.denominationAsset}
          val={denominationAsset.join(', ')}
        />
      </FlexBox>
      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.graphContainer}>
            <Card className={styles.dashboardCard} key={"a"}>
              <div className={styles.cardInner}>
                <div className={styles.cardInnerContent}>
                  <div className={styles.cardInnerContentLeft}>
                    <p>{selectedOption.label}</p>
                    <div className={styles.priceContainer}>
                      {false ? (
                        <h3>
                          <Spinner />
                        </h3>
                      ) : (
                        <>
                          <h3>
                            $ {formatAmountToFixed("2,559.45")}
                          </h3>
                          {/* <span>4.48% APY</span> */}
                        </>
                      )}
                    </div>
                    <h6 className={styles.rate}>+$35.06(+2.14%)</h6>
                    <div className={styles.priceMob}>
                      <div className={styles.priceContainerMob}>
                        {/* <span>4.48% APY</span> */}
                        <h6>+$35.06(+2.14%)</h6>
                      </div>
                    </div>
                  </div>
                  <ReactSelect
                    options={options}
                    value={selectedOption}
                    onChange={(val: any) => {
                      console.log(val);
                      setSelectedOption(val);
                    }}
                    placeholder="All"
                    id=""
                    label=""
                    classNames={styles.graphDropdown}
                    disableError={true}
                    definedStyle={definedStyle}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                  />
                  <SortByYear
                    sortValue={sortValue}
                    handleSortValue={handleSortValue}
                  />
                </div>
                <div className={styles.graph} ref={parentRef}>
                  <FinancialChart
                    key={parentWidth}
                    data={data}
                    height={250}
                    width={parentWidth}
                    sortValue={sortValue}
                    ratio={1}
                  />
                </div>
              </div>
            </Card>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer} >
            <div className={styles.yearOverViewTitle}>
              <h2>Over View By Year</h2>
            </div>

            <DataTables customStyles={customStyles} selectableRows={false} columns={columns} data={dummyData} />

          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.managerContainer}>
            <div className={styles.managerTitle}>
              <h2>Managers</h2>
            </div>
            <div className={styles.manager}>
              <span>Manager</span>
              <CopyToClipboard value="0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e"/>
            </div>
            <div className={styles.manager}>
              <span>Asset Managers</span>
              <CopyToClipboard value="0x51ad1265c8702c9e96ea61fe4088c2e22ed4418e"/>
            </div>
          </div>

        </FlexBox>
      </div>
    </>
  )

};

export default Overview;