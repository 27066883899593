// @ts-nocheck
import clsx from "clsx";
import { FieldError } from "react-hook-form";
import Select, {
  ActionMeta,
  GetOptionLabel,
  MultiValue,
  PropsValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import variable from "../../styles/variables.module.scss";
import styles from "./index.module.scss";
export type Option = {
  value?: string | undefined;
  label?: string | undefined;
};

export interface CustomStyles extends StylesConfig<Option> {
  option?: (styles: any, state: any) => any;
  control?: (styles: any, state: any) => any;
  singleValue?: (base: any, props: any) => any;
  dropdownIndicator?: (base: any, props: any) => any;
} 
interface Props {
  id: string;
  options: Option[];
  value?: PropsValue<Option> | undefined;
  placeholder: string | React.ReactNode;
  defaultValue?: Option;
  label: string;
  onChange: (
    newValue: MultiValue<Option> | SingleValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => void;
  error?: FieldError | undefined;
  isMulti?: boolean;
  errorPosition?: string;
  required?: boolean;
  disabled?: boolean;
  border?: boolean;
  classNames?: string;
  subLabel?: string;
  disableError?: boolean;
  components?:
    | Partial<SelectComponents<Option, boolean, GroupBase<Option>>>
    | undefined;
  definedStyle?: CustomStyles;
  getOptionLabel?: GetOptionLabel<Option>
}


const ReactSelect = (props: Props) => {
  const customStyles: CustomStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? variable["text-dark"] : null,
        background: state.isFocused
          ? variable["select-bg"]
          : state.isSelected
          ? variable["text-dark"]
          : null,
        cursor: "pointer",
        "&:active": {
          backgroundColor: variable["select-bg"],
        },
      };
    },
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      background: "#fafafa !important",
      borderRadius: 12,
      border: !border ? "none" : `1px solid ${variable["input-border"]}`,
      paddingTop: 4,
      paddingBottom: 4,
      fontWeight: 600,
      fontSize: 15,
      cursor: "pointer",
      borderColor: state.isFocused
        ? variable["input-border"]
        : variable["input-border"],
      "&:hover": {
        borderColor: state.isFocused
          ? variable["input-border"]
          : variable["input-border"],
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: !border ? "none" : "block",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 16,
      color: variable["input-placeholder"],
      fontWeight: 400,
      marginLeft: 6,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  const {
    id,
    label,
    options,
    value,
    onChange,
    placeholder,
    error,
    errorPosition,
    required,
    disabled,
    border = true,
    defaultValue,
    classNames,
    subLabel,
    disableError,
    components,
    definedStyle,
    ...rest
  } = props;
  return (
    <div className={clsx(styles.inputContainer, classNames)}>
      {!disableError && <div
        className={clsx(
          styles.labelError,
          errorPosition == "left" ? styles.optional : ""
        )}
      >
        <label htmlFor={props.id} className={clsx(required && styles.star)}>
          {label}
        </label>
        {/* <div className={styles.error}>{error?.message}</div> */}
      </div>}
      <Select
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        styles={definedStyle || customStyles}
        className={error && styles.borderError}
        isDisabled={disabled}
        menuPlacement="bottom"
        menuPortalTarget={document.body}
        components={components}
        {...rest}
      />
      {subLabel && <div>
        <div className={styles.subLabel}>{subLabel}</div>
      </div>}
      <div>
        <div className={styles.error}>{error?.message}</div>
      </div>
    </div>
  );
};

export default ReactSelect;
