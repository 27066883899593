import dayjs from "dayjs";
import { Option } from "../components/Select";

export const dateFormat = (date?: string, format?: string) => {
    return dayjs(date).format(format ?? "YYYY-MM-DD");
};

export const capitalizeText = (text: string) => {
    return text?.charAt(0).toUpperCase() + text.slice(1)
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatSelectOptions = (arr: Option[], options?: string) => {
    return arr.find(item => item.value === options)?.label
}