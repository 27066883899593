import clsx from "clsx";
import Spinner from "../Spinner";

type Props = {
    className?: string
}
const LoadingComponent = (props: Props) => {
    const { className } = props
    return (
        <div className={clsx("loaderContainer", className)}>
            <Spinner />
        </div>
    );
};
export default LoadingComponent