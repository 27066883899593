import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";
type Props = {
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  loading?: boolean;
  variant?: "primary" | "secondary" | "outlined" | "link" | "pillFilter" | "pillFilterOutlined";
  type?: "button" | "submit" | "reset";
  hide?: boolean;
};
export default function CustomButton(
  props: Props & Omit<React.HTMLProps<HTMLButtonElement>, "type">
) {
  const {
    startIcon,
    endIcon,
    loading,
    variant = "primary",
    children,
    type = "button",
    className,
    hide,
    ...rest
  } = props;
  return (

<>
{
  !hide && 
<button
className={clsx(styles.button, styles[variant], className)}
{...rest}
>
{loading ? (
  <span>Loading...</span>
) : (
  <>
    {startIcon}
    {children}
    {endIcon}
  </>
)}
</button>
}

</>
 
  );
}
