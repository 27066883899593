import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";

import { theme } from "./styles/theme";
import "react-responsive-modal/styles.css";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import ErrorBoundary from "./components/ErrorBoundary";
import AlertContext from "./context/AlertContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const mode = "light";
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme({ mode })}>
      <BrowserRouter>
        <ErrorBoundary>
          <Provider store={store}>
            <AlertContext>
              <CssBaseline />
              <App />
            </AlertContext>
          </Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
reportWebVitals();
