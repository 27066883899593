import clsx from "clsx";
import styles from "../pages/Login/index.module.scss";
import { AppLogo, PlatformIcon, SignupImg } from "../assets";

type Props = {
    children: React.ReactNode;
    title?: string;
    caption?: string;
}
const AuthLayout = (props: Props) => {
    const { children, title, caption } = props;
    return (
        <div className={clsx(styles.signup_page, styles.bg)}>
            <div className={styles.logo}>
                {/* <AppLogo /> */}
            </div>
            <div className={styles.sign_img}>
                <div className={styles.img}>
                    {/* <SignupImg /> */}
                    <PlatformIcon />
                </div>
                <div className={styles.diversify_text}>
                    <h4>{title}</h4>
                    <p>
                        {caption}
                    </p>
                </div>
            </div>
            <div className={styles.sign_form}>
                {children}
            </div>
        </div>
    );
};
export default AuthLayout;
