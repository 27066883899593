import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";

export interface alertState {
  alert: {
    externalId: string;
    alertLevel: "SEVERE" | "HIGH" | "MEDIUM" | "LOW";
    service: number | null;
    alertAmount: Number;
    exposureType: "DIRECT" | "INDIRECT" | null;
    categoryId: number | null;
    status: "PENDING" | "DONE";
    createdAt: string;
    updatedAt: string;
    user: User | null
  } | null;
}

const initialState: alertState = {
  alert: null,
};

export const transactionAlertSlice = createSlice({
  name: "transactionAlertSlice",
  initialState,
  reducers: {
    setTransactionAlerts: (state, action: PayloadAction<alertState>) => {
      console.log('action: ', action.payload);
      state.alert = action.payload.alert;
    },
    clearTransactionAlerts: (state, action: PayloadAction<void>) => {
      state.alert = null;
    },
  },
});

export const { setTransactionAlerts, clearTransactionAlerts } =
  transactionAlertSlice.actions;

export default transactionAlertSlice.reducer;
