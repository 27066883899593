import  { ReactNode } from "react";
import styles from './index.module.scss'
import Card from "../Card";

type props = {
  icon?: ReactNode;
  heading: string;
  val: number | string;
  dollar?: boolean
  valueClassName?: string;
};

const IndicatorTokenCard = ({ icon, heading, val , valueClassName, dollar = false }: props) => {
    const formattedValue = typeof(val) === "number" ? new Intl.NumberFormat('en-US').format(val) : val;
  return (
    <Card className={styles.indicatorCard}>
      <div className={styles.tokenCard}>
        <div >
          {icon && <span>{icon}</span>}
          <p>{heading}</p>
        </div>
        <div className={valueClassName}>
            {dollar && "$"}
            {formattedValue}</div>
      </div>
    </Card>
  );
};

export default IndicatorTokenCard;
