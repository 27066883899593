import { createTheme, PaletteMode } from '@mui/material';

type Props = {
    mode: PaletteMode | undefined
}
export const theme =({mode}:Props)=> createTheme({
    palette: {
      primary: {
        main: "#28255F"
      },
      secondary: {
        main: "#EEE9F6"
      },
      mode:mode
    }
  })
  