import CustomButton from "../CustomButton";
import styles from "./index.module.scss";
import { CircularTick } from "../../assets";
type PillFilterProps = {
    name: string;
    isSelected: boolean;
    filterClick: () => void;
}
const PillFilter = (props: PillFilterProps) => {
    return (
        <>
            <CustomButton
                className={styles.pillFilter}
                variant={props.isSelected ? "pillFilter" : "pillFilterOutlined"}
                onClick={props.filterClick}
                endIcon={props.isSelected ? <CircularTick/>: <></>}
            >
                {props.name}
            </CustomButton>
    </>
    )
    
}

export default PillFilter