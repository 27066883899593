import IndicatorTokenCard from "../../IndicatorTokenCard/IndicatorTokenCard";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";


const Financial = () => {

  return (
    <>
      <h2 className={styles.title}>Financial</h2>

      <div className={styles.productContainer}>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Basics</h2>
            </div>
            <FlexBox className={styles.flexTokenCard}>
              <IndicatorTokenCard
                key={1}
                valueClassName={styles.valueClassName}
                heading="Market Cap."
                val={"$685.14"}
              />
              <IndicatorTokenCard
                key={1}
                heading="Volume (24h)"
                val={"$37.74K"}
              />
              <IndicatorTokenCard
                key={1}
                heading="Current Supply"
                val={"408.83"}
              />
              <IndicatorTokenCard
                key={1}
                heading="Streaming Fee"
                val="0.25%"
              />
              <IndicatorTokenCard
                key={1}
                heading="Mint Fee"
                val="n/a"
              />
              <IndicatorTokenCard
                key={1}
                heading="Redeem Fee"
                val="n/a"
              />
              <IndicatorTokenCard
                key={1}
                heading="NAV"
                val="$1.61K"
              />
            </FlexBox>
          </div>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <div className={styles.yearOverviewContainer}>
            <div className={styles.yearOverViewTitle}>
              <h2>Risk metrics (Trailing 30 days)</h2>
            </div>
            <FlexBox className={styles.flex}>
              <IndicatorTokenCard
                key={1}
                valueClassName={styles.valueClassName}
                heading="Annualised Volatility"
                val={"59.15%"}
              />
              <IndicatorTokenCard
                key={1}
                valueClassName={styles.sharpRatio}
                heading="Sharpe Ratio"
                val={2.07}
              />
            
            </FlexBox>
          </div>
        </FlexBox>
      </div>
    </>
  )

};

export default Financial;