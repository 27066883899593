import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import AppLayout from "./layout/AppLayout";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { loadUser } from "./store/reducers/authSlice";
import Login from "./pages/Login";
import Spinner from "./components/Spinner";
import ChangePassword from "./pages/ChangePassword";
import UnautorizeAccess from "./pages/StaticPage/UnautorizeAccess";
import Otp2FA from "./pages/Otp2FA";
import SocketContext from "./context/Socket";
import SessionLayout from "./SessionLayout";
import { useGetProfileQuery } from "./service/api";
import Can from "./components/Can";
import NotFound from "./components/NotFound";
import NFTS from "./pages/NFTS";
import IndicatorToken from "./pages/IndicatorToken";
import TransactionAlerts from "./pages/TransactionAlerts";
import TransactionAlertsSumSub from "./pages/TransactionAlertsSumsub";
const UserManagement = lazy(() => import("./pages/UserManagement"));
const SubAdmins = lazy(() => import("./pages/SubAdmins"));
const AffiliateManagement = lazy(() => import("./pages/AffiliateManagement"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const LoyaltyProgram = lazy(() => import("./pages/LoyaltyProgram"));
const SavingPlans = lazy(() => import("./pages/SavingPlans"));
const NewPassword = lazy(() => import("./pages/NewPassword"));
const QRCode = lazy(() => import("./components/QRCode"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const SubadminProfile = lazy(() => import("./pages/SubadminProfile"));
const AffiliateDetail = lazy(() => import("./pages/AffiliateDetail"));
const AccountStatus = lazy(() => import("./pages/AccountStatus"));
const Profile = lazy(() => import("./pages/Profile"));
const GDPRManagement = lazy(() => import("./pages/GDPRmanagement"));
const News = lazy(() => import("./pages/News"));
const AffiliateSystem = lazy(() => import("./pages/AffiliateSystem"));
const DepositAddresses = lazy(() => import("./pages/DepositAddresses"));
const AppSettings = lazy(() => import("./pages/AppSettings"));
const Vault = lazy(() => import("./pages/Vault"));
const ListVault = lazy(() => import("./pages/SelectToken"));

const AuthRoute = () => {
  const { authenticated, isLoading } = useAppSelector((state) => state.auth);

  if (isLoading) {
    return <Loader />;
  }

  if (!authenticated && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export const Loader = () => {
  return (
    <div className="loaderContainer">
      <Spinner />
    </div>
  );
};

const PublicRoutes = () => {
  const { authenticated, isLoading } = useAppSelector((state) => state.auth);
  if (isLoading) {
    return <Loader />;
  }

  if (authenticated && !isLoading) {
    return <Navigate to="/" replace />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />;
    </Suspense>
  )

};

function App() {
  const dispatch = useAppDispatch();

  const { isLoading, token } = useAppSelector((state) => state.auth);
  const { isLoading: dataLoading, data } = useGetProfileQuery(undefined, {
    skip: !token,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const loading = isLoading || dataLoading;

  useEffect(() => {
    if (data?.data) {
      dispatch(loadUser({ user: data?.data }));
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <SocketContext>
      <SessionLayout>
        <Routes>
          <Route path="/unauthorized" element={<UnautorizeAccess />} />
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/2fa-auth" element={<Otp2FA />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/2fa-qrcode" element={<QRCode />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route element={<AppLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/user-management/:id" element={<MyProfile />} />
              <Route path="/sub-admins" element={<SubAdmins />} />
              <Route path="/sub-admins/:id" element={<SubadminProfile />} />
              <Route path="/saving-plans" element={<SavingPlans />} />
              <Route path="/loyalty-program" element={<LoyaltyProgram />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/gdpr-management" element={<GDPRManagement />} />
              <Route path="/news" element={<News />} />
              <Route
                path="/affiliate-management"
                element={<AffiliateManagement />}
              />
                 <Route
                path="/txn-alerts"
                element={<TransactionAlertsSumSub />}
              />
               <Route
                path="/txn-alerts/chain-analysis"
                element={<TransactionAlerts />}
              />
               <Route
                path="/txn-alerts/sumsub"
                element={<TransactionAlertsSumSub />}
              />

              <Route
                path="/affiliate-management/:id"
                element={<AffiliateDetail />}
              />
              <Route path="/affiliate" element={<AffiliateSystem />} />
              <Route
                path="/settings"
                element={<Can.Admin element={() => <Outlet />} />}
              >
                <Route
                  path="transactions-limit"
                  element={<Can.Admin element={AccountStatus} />}
                />
                <Route
                  path="deposit-addresses"
                  element={<Can.Admin element={DepositAddresses} />}
                />
                <Route
                  path="app-settings"
                  element={<Can.Admin element={AppSettings} />}
                />
              </Route>
              <Route path="product" element={<Outlet />}>
                <Route
                  index
                  path="staking"
                  element={<h1>Product = Staking</h1>}
                />
                <Route path="myToken" element={<h1>Product = myToken</h1>} />
                <Route path="neal" element={<h1>Product = NEAL</h1>} />
                <Route path="info" element={<h1>Product = Info</h1>} />
              </Route>
              <Route path="vault" element={<Vault />} />
              <Route path="list-vault" element={<ListVault />} />
                <Route path="help-center" element={<h1>Indicator-token = help-center</h1>} />
                <Route path="indicator-tokens/overview" element={<IndicatorToken/>} />
              <Route path="indicator-tokens/:token/:id" element={<NFTS />}/>
    

            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </SessionLayout>
    </SocketContext>
  );
}
export default App;
