import { useState, useEffect, PropsWithChildren } from "react";
import "./index.scss";
import {
  discontinuousTimeScaleProvider,
  Chart,
  ChartCanvas,
  AreaSeries,
  XAxis,
  YAxis,
  CrossHairCursor,
  MouseCoordinateX,
  MouseCoordinateY,
  HoverTooltip,
  Label,
  MoreProps,
} from "react-financial-charts";
import moment from "moment";

const themeColor = "#9c86ce";

type SortValue = "1y" | "1m" | "1w" | "24h" | "12h";
const labelFormatMap: Record<SortValue, string> = {
  "1y": "MMM YY",
  "1m": "D MMM",
  "1w": "ddd",
  "24h": "hh a",
  "12h": "hh a",
};

interface Props extends PropsWithChildren<any> {
  height: number;
  width: number;
  data: any;
  sortValue: SortValue;
  ratio: number;
  plotFull: boolean;
  seriesName: string;
  clamp: boolean;
  disablePan: boolean;
  disableZoom: boolean;
  axisZoomEnabled: boolean;
  xAxisAt: number | "bottom" | "top" | "middle" | undefined;
  yAxisAt: number | "middle" | "left" | "right" | undefined;
  margin:
    | {
        bottom: number;
        left: number;
        right: number;
        top: number;
      }
    | undefined;
  coordinateStyles: {
    fill: string;
    textFill: string;
  };
  crossHairStyles: {
    strokeStyle: string;
  };
  axisStyles: {
    tickLabelFill: string;
  };
  areaSeriesFillStyle: (
    context: CanvasRenderingContext2D,
    moreProps: MoreProps
  ) => string | CanvasGradient | CanvasPattern;
  areaSeriesStrokeStyle: string;
}

const defaultAreaSeriesFillStyle = (context: CanvasRenderingContext2D, moreProps: MoreProps) => {
  const { chartConfig } = moreProps;
  const gradient = context.createLinearGradient(
    0,
    chartConfig?.yScale?.range()[0] || 0,
    0,
    chartConfig?.yScale.range()[1] || 0
  );
  gradient.addColorStop(1, themeColor);
  gradient.addColorStop(0, "rgba(255,255,255,0)");
  return gradient;
};

const FinancialChart = ({
  height,
  width,
  data,
  sortValue,
  ratio,
  plotFull,
  seriesName,
  clamp,
  disablePan,
  disableZoom,
  axisZoomEnabled,
  xAxisAt,
  yAxisAt,
  margin,
  coordinateStyles,
  crossHairStyles,
  axisStyles,
  areaSeriesStrokeStyle,
  areaSeriesFillStyle = defaultAreaSeriesFillStyle,
} = defaultProps) => {
  const [isData, setIsData] = useState(false);
  const { labels, datasets } = data;
  const chartData = datasets[0].data;

  const customFormat = labelFormatMap[sortValue] || "MMM YY";
  const formattedData = labels.map((label: string, index: number) => {
    const date = moment(label, customFormat).toDate();
    return {
      name: date,
      value: chartData[index],
    };
  });
  useEffect(() => {
    if (formattedData.length > 0) {
      setIsData(true);
    }
  }, [formattedData]);
  const formatTick = (index: number) => {
    return labels[index];
  };

  const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
    (d) => d?.name
  );

  const {
    data: xScaleData,
    xScale,
    xAccessor,
    displayXAccessor,
  } = xScaleProvider(formattedData);

  const tooltipContent = ({ currentItem }: any) => ({
    x: currentItem
      ? moment(currentItem?.name).format(customFormat)
      : currentItem?.name,
    y: [
      {
        label: "Value",
        value: `$${
          currentItem
            ? currentItem?.value && currentItem?.value.toFixed(2)
            : undefined
        }`,
      },
    ],
  });

  return (
    <ChartCanvas
      height={height}
      width={width}
      plotFull={plotFull}
      margin={margin}
      seriesName={seriesName}
      data={xScaleData}
      xScale={xScale}
      xAccessor={xAccessor}
      displayXAccessor={displayXAccessor}
      ratio={ratio}
      xExtents={[0, xScaleData.length]}
      clamp={clamp}
      disablePan={disablePan}
      disableZoom={disableZoom}
      disableInteraction={isData ? false : true}
    >
      <Chart
        id={1}
        yExtents={(d) => [
          0,
          Math.max(...formattedData.map((d: Record<string, string>) => parseFloat(d.value))),
        ]}
      >
        <XAxis
          axisAt={xAxisAt}
          orient="bottom"
          tickFormat={formatTick}
          {...axisStyles}
          zoomEnabled={axisZoomEnabled}
        />
        <MouseCoordinateX
          rectWidth={margin?.left}
          displayFormat={(d) => moment(d).format(customFormat)}
          {...coordinateStyles}
        />

        <YAxis
          axisAt={yAxisAt}
          orient="left"
          ticks={5}
          tickFormat={(value) => value.toString()}
          {...axisStyles}
          zoomEnabled={axisZoomEnabled}
        />
        <MouseCoordinateY
          rectWidth={margin && -margin.right}
          displayFormat={(d) => d.toFixed(2)}
          {...coordinateStyles}
          at="left"
        />

        <AreaSeries
          yAccessor={(d) => parseFloat(d.value)}
          strokeStyle={areaSeriesStrokeStyle}
          fillStyle={areaSeriesFillStyle}
        />
        {!isData && (
          <Label
            text="No Data"
            x={width / 2 - (margin?.right ?? 0)}
            y={height / 2}
            fillStyle="#DCDCDC"
            fontSize={32}
            fontFamily="Nunito Sans"
            fontWeight="bold"
            rotate={0}
            textAlign="center"
          />
        )}
        <HoverTooltip
          yAccessor={(d) => parseInt(d.value)}
          background={{
            fillStyle: "transparent",
          }}
          tooltip={{
            content: tooltipContent,
          }}
          backgroundShapeCanvas={(props, bgSize, ctx) => {
            const borderRadius = 5;
            const boxShadow = "2px 2px 5px rgba(0, 0, 0, 0.2)";
            const borderColor = "red";

            ctx.fillStyle = "white";
            ctx.beginPath();
            ctx.moveTo(borderRadius, 0);
            ctx.lineTo(bgSize.width - borderRadius, 0);
            ctx.quadraticCurveTo(bgSize.width, 0, bgSize.width, borderRadius);
            ctx.lineTo(bgSize.width, bgSize.height - borderRadius);
            ctx.quadraticCurveTo(
              bgSize.width,
              bgSize.height,
              bgSize.width - borderRadius,
              bgSize.height
            );
            ctx.lineTo(borderRadius, bgSize.height);
            ctx.quadraticCurveTo(
              0,
              bgSize.height,
              0,
              bgSize.height - borderRadius
            );
            ctx.lineTo(0, borderRadius);
            ctx.quadraticCurveTo(0, 0, borderRadius, 0);
            ctx.closePath();
            ctx.fill();
            ctx.shadowColor = "rgba(0, 0, 0, 0.2)";
            ctx.shadowBlur = 5;
            ctx.shadowOffsetX = 2;
            ctx.shadowOffsetY = 2;
            ctx.stroke();
          }}
        />
        <CrossHairCursor {...crossHairStyles} />
      </Chart>
    </ChartCanvas>
  );
};
const defaultProps: Props = {
  height: 250,
  width: 990,
  sortValue: "24h",
  ratio: 1,
  data: {
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
  },
  plotFull: true,
  seriesName: "Data",
  clamp: false,
  disablePan: true,
  disableZoom: true,
  axisZoomEnabled: false,
  xAxisAt: "bottom",
  yAxisAt: "left",
  margin: {
    left: 50,
    right: 50,
    top: 10,
    bottom: 30,
  },
  coordinateStyles: {
    fill: themeColor,
    textFill: "#FFFFFF",
  },
  crossHairStyles: {
    strokeStyle: "#383E55",
  },
  axisStyles: {
    tickLabelFill: "#5e648f",
  },
  areaSeriesFillStyle: defaultAreaSeriesFillStyle,
  areaSeriesStrokeStyle: themeColor,
};
FinancialChart.defaultProps = defaultProps;
export default FinancialChart;
