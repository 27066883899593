import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const resetPasswordSchema = yup.object({
  email: yup.string().email().required("Email is required"),
});

export const subAdminOnboarding = yup.object({
  userName: yup
    .string()
    .trim()
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .required("Username is required"),
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});
export const newPasswordSchema2 = yup.object({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});
const phoneRegExp = /^(?:\+\d{1,3}\s?)?\d{10,15}$/;

export const personalInfoSchema = yup.object({
  userName: yup
    .string()
    .trim()
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .required("Username is required"),
  email: yup.string().required("Email is required"),
  legalName: yup.string().required("Legal name is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  dob: yup.string().required("DOB is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  nationality: yup.object().shape({
    label: yup.string().required("Nationality is required"),
    value: yup.string().required(),
  }),
  countryOfResidence: yup.object().shape({
    label: yup.string().required("Country of residence is required"),
    value: yup.string().required(),
  }),
  timezone: yup.object().shape({
    label: yup.string().required("Country of residence is required"),
    value: yup.string().required(),
  }),
});

export const subadminSchema = yup.object({
  userName: yup
    .string()
    .trim()
    .matches(/^[a-z][a-z0-9_]{4,29}$/, "Username format is not correct")
    .required("Username is required"),
  email: yup.string().required("Email is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  timezone: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
});

export const addSubadminSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required")
    .test("Validate Email", "Enter a valid email", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    }),
});

export const savingPlanSchema = yup.object({
  title: yup.string().min(0).max(20).required("Title is required"),
  average: yup.string().required("Average is required"),
  all: yup.bool().optional(),
  NFTS: yup.bool().optional(),
  nSTBL: yup.bool().optional(),
  description: yup.string().min(0).max(50).required("Description is required"),
  duration: yup.string().required("Duration is required"),
});

export const campaignSchema = yup.object({
  title: yup.string().required("Reward name is required"),
  description: yup.string().required("Description is required"),
  rewardCost: yup.string().required("Reward cost is required"),
  amount: yup.string().required("Amount is required"),
  duration: yup.string().required("Duration is required"),
  startAt: yup.string().optional(),
  endAt: yup.string().optional(),
  claimablePeriod: yup.string().required("Period is required"),
  link: yup
    .string()
    .url(
      "Please enter a valid URL. (use 'https' or 'http') ex: https://www.example.com"
    )
    .notRequired(),
  isLimit: yup.bool().optional(),
  limitPerCustomer: yup.string().optional(),
  isAdditionalInformation: yup.bool().optional(),
  additionalInfo: yup.string().optional(),
});
export const updateProfileSchema = yup.object({
  email: yup.string().optional(),
  firstname: yup.string().required("Firstname is required"),
  lastname: yup.string().required("Lastname is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  timeZone: yup.object().shape({
    label: yup.string().optional(),
    value: yup.string().optional(),
  }),
});
export const tierSchema = yup.object({
  tierLevel: yup.string().required("Tier level is required"),
  points: yup.number().optional().typeError("Points must be a number"),
  amount: yup.number().optional().typeError("Amount must be a number"),
});
export const changePasswordSchema = yup.object({
  oldPassword: yup.string().required("Old password is required"),
  password: yup.string().required("New password is required"),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});
export const loyalityLevelSchema = yup.object({
  loyaltyLevel: yup.string().required("Loyalty level is required"),
  stakingApy: yup.string().required("Staking apy is required"),
  investmentPreTokenLaunch: yup
    .string()
    .required("Investment pre token is required"),
  nftReward: yup.string().required("NFT - reward is required"),
  loyaltyPoints: yup
    .number()
    .required("Loyalty - Points is required")
    .typeError("Loyalty - Points must be a number"),
});

export const accountStatusSchema = yup.object({
  withdrawlLimit: yup
    .number()
    .typeError("Withdrawal limit should be number")
    .required("Withdrawal limit is required"),
  minimumWithdrawalLimit: yup
    .number()
    .typeError("Min withdrawal limit should be number")
    .required("Min withdrawal limit is required"),
  maxBuyLimit: yup
    .number()
    .typeError("Withdrawal limit should be number")
    .required("Withdrawal limit is required"),
  minBuyLimit: yup
    .number()
    .typeError("Min withdrawal limit should be number")
    .required("Min withdrawal limit is required"),
});
