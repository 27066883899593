import clsx from "clsx";
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  ActiveAlertIcon,
  AffiliateActiveIcon,
  AfilliateIcon,
  AlertIcon,
  DashboardActive,
  LoyalActive,
  PlanActive,
  ProductActive,
  UserIcon,
  UserIconActive,
  UserList,
  UserListActive,
  UsersIcon,
  UsersIconActive,
} from "../../assets";
import { ReactComponent as Index } from "../../assets/icons/Index.svg";
import { ReactComponent as Loyalty } from "../../assets/icons/Loyalty .svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";
import { ReactComponent as MenuArrow } from "../../assets/icons/menu-arrow.svg";
import { ReactComponent as Saving } from "../../assets/icons/saving plan.svg";
import { ReactComponent as CircularPlussIcon } from "../../assets/icons/circular-plus-white.svg";

import { useAppSelector } from "../../store/hooks";
import style from "./index.module.scss";
import {
  PermContactCalendarOutlined,
  Newspaper,
  Wallet,
  Settings,
} from "@mui/icons-material";
import Logout from "../Logout";
import { useGetPlatformTokensQuery } from "../../service/api";
import CustomButton from "../CustomButton";
import useParentWidth from "../../Hooks/useParentWidth";

type IMenu = {
  to: string;
  label: string;
  icon?: React.ReactElement;
  activeIcon?: React.ReactElement;
  children?: IMenu[];
  onClick?: () => void;
  toggle?: (() => void) | undefined;
  routeName?: string;
  setRouteName?: Dispatch<SetStateAction<string | undefined>>;
  role?: string[];
  isTokens?: boolean;
  helpChild?: IMenu
};





export type SideBarRef = {
  toggleSideBar?: () => void;
};

type Props = {
  onChange?: (width: number) => void;
  toggleActiveClass: () => void;
};

const Sidebar = forwardRef<SideBarRef, Props>((props, ref) => {
  const [routeName, setRouteName] = useState<string | undefined>("/boarding");
  const { parentRef, parentWidth } = useParentWidth();
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { toggleActiveClass } = props;
  const toggleSideBar = () => {
    sidebarRef.current?.classList.toggle(style.active);
    props.onChange?.(sidebarRef.current?.clientWidth || 0);
    setTimeout(() => {
      props.onChange?.(sidebarRef.current?.clientWidth || 0);
    }, 700);
  };

  useImperativeHandle(
    ref,
    () => ({
      toggleSideBar,
    }),
    [sidebarRef.current]
  );

  useEffect(() => {
    props.onChange?.(sidebarRef.current?.clientWidth || 0);
  }, []);

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const toggleLogoutModal = () =>
    setIsLogoutModalOpen((isLogoutModalOpen) => !isLogoutModalOpen);
  const navigateLogout = () => {
    toggleLogoutModal();
    navigate(-1);
  };
  const [productChilds, setProductChilds] = useState<IMenu[]>([])
  const { data, isLoading } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const products: IMenu[] = [];
    for (const token of data?.data || []) {
      products.push(
        {
          to: `/indicator-tokens/${token.symbol}/${token.id}`,
          icon: <Index />,
          activeIcon: <ProductActive />,
          label: token.name,
          isTokens: true
        }
      )
    }

    setProductChilds(products)
  }, [data, isLoading])
  const navigations: IMenu[] = [
    {
      to: "/",
      icon: <Dashboard />,
      activeIcon: <DashboardActive />,
      label: "Statistics",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/indicator-tokens/overview",
      icon: <Index />,
      activeIcon: <ProductActive />,
      label: "Indicator Tokens",
      children: [
        ...productChilds
      ],
      role: ["Admin", "SubAdmin"],
      isTokens: true,
      // helpChild: { to: "/help-center", icon: <Index />, label: "Help center" },
    },
    {
      to: "/txn-alerts",
      icon: <AlertIcon />,
      activeIcon: <ActiveAlertIcon />,
      label: "Transaction Alerts",
      children: [
        { to: "txn-alerts/chain-analysis", icon: <Index />, label: "Chain Analysis" },
        { to: "txn-alerts/sumsub", icon: <Index />, label: "Sumsub" },
      ],
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/user-management",
      icon: <UsersIcon />,
      activeIcon: <UsersIconActive />,
      label: "User Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/sub-admins",
      icon: <UserIcon />,
      activeIcon: <UserIconActive />,
      label: "Subadmins",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/saving-plans",
      icon: <Saving />,
      activeIcon: <PlanActive />,
      label: "Saving Plans",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/loyalty-program",
      icon: <Loyalty />,
      activeIcon: <LoyalActive />,
      label: "Loyalty Program",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/gdpr-management",
      icon: <UserList />,
      activeIcon: <UserListActive />,
      label: "GDPR Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/affiliate-management",
      icon: <AfilliateIcon />,
      activeIcon: <AffiliateActiveIcon />,
      label: "Affiliate Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/news",
      icon: <Newspaper />,
      activeIcon: <Newspaper color="primary" />,
      label: "News",
    },
    {
      to: "/settings",
      icon: <Settings />,
      activeIcon: <Settings color="primary" />,
      label: "Settings",
      role: ["Admin"],
      children: [
        {
          to: "/settings/transactions-limit",
          label: "Transaction Limits",
          role: ["Admin"],
        },
        {
          to: "/settings/deposit-addresses",
          label: "Deposit Addresses",
        },
        {
          to: "/settings/app-settings",
          label: "App settings",
        },
      ],
    },
  ];
  const navigationsSubAdmin: IMenu[] = [
    {
      to: "/",
      icon: <Dashboard />,
      activeIcon: <DashboardActive />,
      label: "Statistics",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/indicator-tokens/overview",
      icon: <Index />,
      activeIcon: <ProductActive />,
      label: "Indicator Tokens",
      children: [
        ...productChilds
      ],
      role: ["Admin", "SubAdmin"],
      isTokens: true,
      // helpChild: { to: "/help-center", icon: <Index />, label: "Help center" },
    },
    {
      to: "/user-management",
      icon: <UsersIcon />,
      activeIcon: <UsersIconActive />,
      label: "User Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/sub-admins",
      icon: <UserIcon />,
      activeIcon: <UserIconActive />,
      label: "Subadmins",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/saving-plans",
      icon: <Saving />,
      activeIcon: <PlanActive />,
      label: "Saving Plans",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/loyalty-program",
      icon: <Loyalty />,
      activeIcon: <LoyalActive />,
      label: "Loyalty Program",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/gdpr-management",
      icon: <UserList />,
      activeIcon: <UserListActive />,
      label: "GDPR Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/affiliate-management",
      icon: <AfilliateIcon />,
      activeIcon: <AffiliateActiveIcon />,
      label: "Affiliate Management",
      role: ["Admin", "SubAdmin"],
    },
    {
      to: "/news",
      icon: <PermContactCalendarOutlined />,
      activeIcon: <PermContactCalendarOutlined color="primary" />,
      label: "News",
    },
  ];
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div ref={sidebarRef} className={clsx(style.sidebar)}>
      <div
        className={style.loggle}
        onClick={() => {
          toggleSideBar();
          toggleActiveClass();
        }}
      >
        <CrossIcon className={style.close} />
        <MenuArrow className={style.open} />
      </div>
      <div ref={parentRef} className={style.scroll}>
        <div className={style.logo}>
          <Logo />
        </div>
        <div className={style.menu}>
          <ul className={style.sidebarUl}>
            {user?.role == "Admin"
              ? navigations.map((navigation, index) => (
                <Menu
                  {...navigation}
                  key={index}
                  toggle={() => toggleSideBar()}
                  routeName={routeName}
                  setRouteName={setRouteName}
                />
              ))
              : navigationsSubAdmin.map((navigation, index) => (
                <Menu
                  {...navigation}
                  key={index}
                  toggle={() => toggleSideBar()}
                  routeName={routeName}
                  setRouteName={setRouteName}
                />
              ))}
          </ul>
          <ul className={clsx(style.LogOut, style.sidebarUl)}>
            <div onClick={toggleLogoutModal}>
              <Menu
                to="#logout"
                icon={<LogOut />}
                activeIcon={<LogOut />}
                label="Logout"
                routeName={routeName}
                setRouteName={setRouteName}
              />
            </div>
          </ul>

          <Logout isOpen={isLogoutModalOpen} toggle={navigateLogout} />
          <ul  className={style.createTokenContainer}>
            <CustomButton
            className={style.createTokenButton}
            startIcon={parentWidth < 100 ? <CircularPlussIcon />: <></>}
            onClick={() => { navigate("/vault") }}
          >
            {parentWidth  > 100 ? "Create new token" : ""}
          </CustomButton>
          </ul>
        </div>
        
      </div>
      
    </div>
  );
});
export default Sidebar;

function Menu(props: IMenu) {
  const navigate = useNavigate();
  const [newActive, setNewActive] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const {
    to,
    label,
    icon,
    activeIcon,
    children,
    toggle,
    routeName,
    setRouteName,
    isTokens,
    helpChild
  } = props;

  const changeHandler = () => {
    setRouteName?.(to);
    if (toggle !== undefined && window.innerWidth < 768) {
      toggle();
    }
  };
  const handleOnClick = (e: any) => {
    e.preventDefault();
    setIsActive((current) => !current);
    navigate(to)
  };


  const checkRoute = routeName == to && to !== "#logout";

  if (children && children.length) {
    console.log(isTokens);
    return (
      <div
        className={clsx(style.dropMenu, isActive ? style.activeDropMenu : "")}
      >
        <li className={clsx(checkRoute ? style.active : "")}>
          <NavLink
            to={to}
            onClick={(e: any) => handleOnClick(e)}
            className={({ isActive }) => clsx(isActive ? style.active : "")}
          >
            {({ isActive }) => {
              return (
                <>
                  <span>{isActive ? activeIcon : icon}</span>
                  <p
                    className={clsx(
                      style.label,
                      isActive ? style.textActive : ""
                    )}
                  >
                    {label}
                  </p>
                  <span className={style.arrow}>
                    <Arrow />
                  </span>
                </>
              );
            }}
          </NavLink>
        </li>

        <ul className={isTokens?style.tokensdropmenu:style.opendropmenu} onClick={changeHandler}>
          {children.map((child, index) => (
            <Menu
              {...child}
              key={index}
              routeName={routeName}
              setRouteName={setRouteName}
            />
          ))}
        </ul>
        {
          helpChild &&
          <>
            <ul className={style.opendropmenu} onClick={changeHandler}>
              <Menu
                {...helpChild}
                routeName={routeName}
                setRouteName={setRouteName}
              />
            </ul>
          </>
        }

      </div>
    );
  }
  return (
    <li
      className={newActive ? style.active : ""}
      onClick={() => changeHandler()}
    >
      <NavLink to={to}>
        {({ isActive, isPending }) => {
          setNewActive(isActive && to !== "#logout");
          return (
            <>
              <span>{isActive && to !== "#logout" ? activeIcon : icon}</span>
              <p
                className={clsx(
                  style.label,
                  isActive && to !== "#logout" ? style.textActive : ""
                )}
              >
                {label}
              </p>
            </>
          );
        }}
      </NavLink>
    </li>
  );
}
