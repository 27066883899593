import clsx from "clsx";
import { ChangeEvent, ReactNode, KeyboardEventHandler, forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";
import styles from "./index.module.scss";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export interface Props {
    type: string;
    label?: string;
    placeholder: string;
    id: string;
    name: string;
    error?: FieldError | undefined;
    textCenter?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlurFunction?: (e: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement> | undefined;
    noWidth?: boolean;
    value?: string | number;
    maxLength?: number;
    className?: string;
    max?: string;
    maxDate?: string;
    disabled?: boolean;
    info?: ReactNode
    required?: boolean;
    subLabel?: string;
    // inputRef?: React.RefObject<HTMLInputElement>|null,
}
const Input = forwardRef((props: Props, ref) => {
    const {
        label,
        type,
        error,
        onChange,
        onBlur,
        onBlurFunction,
        onKeyDown,
        noWidth,
        className,
        textCenter,
        maxLength,
        maxDate,
        info,
        required,
        subLabel,
        ...rest
    } = props;
    const [isShowPassword, setIsShowPassword] = useState(false)
    const showPasswordHandler = () => {
        setIsShowPassword(isShowPassword => !isShowPassword)
    }

    return (
        <div
            className={clsx(
                styles.inputContainer,
                className,
                noWidth ? styles.width0 : styles.width100
            )}
        >
            <label
                className={clsx(styles.labelText, !label && styles.displayNone, required && styles.star)}
                htmlFor={props.id}
            >
                {label}
                {info && <span className={styles.info}>{info}</span>}

            </label>
            <div className={styles.inputRadio}>
                {type === "textarea" ? (
                    <textarea
                        onChange={onChange}
                        maxLength={maxLength}
                        className={clsx(
                            textCenter ? styles.text_center : styles.text_start,
                            error ? styles.borderError : styles.borderNormal,
                            styles.textContentArea, styles.inputPlaceholder
                        )}
                        {...rest}
                    ></textarea>
                ) : (
                    <div className={clsx(error ? styles.borderError : styles.borderNormal, styles.inputBox)}>
                        <input
                            type={isShowPassword ? "text" : type}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            max={maxDate}
                            maxLength={maxLength}
                            className={clsx(
                                textCenter ? styles.text_center : styles.text_start,
                                // error ? styles.borderError : styles.borderNormal,
                                type === "date" ? styles.datePlaceholder : styles.inputPlaceholder,
                                styles.input
                            )}
                            onBlur={onBlurFunction}
                            {...rest}
                        />

                        {type === "password" && props?.value && <div>
                            {isShowPassword ?
                                <div className={styles.showPassword} onClick={showPasswordHandler}>
                                    <RemoveRedEyeOutlinedIcon />
                                </div>
                                :
                                <div className={styles.showPassword} onClick={showPasswordHandler}>
                                    <VisibilityOffOutlinedIcon />
                                </div>
                            }
                        </div>}

                    </div>

                )}
            </div>
            
            {subLabel && <div className={clsx(styles.subLabel)}>
                {subLabel?.length > 0 && subLabel}
            </div>}
            <div className={clsx(styles.error, !error && styles.displayNone)}>
                {error?.message}
            </div>
        </div>
    );
});
export default Input;
