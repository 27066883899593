import { ReactElement, useState } from "react";
import FlexBox from "../../common/FlexBox";
import styles from "./index.module.scss";
import DataTables, { Data } from "../../DataTables";
import { FoxIcon, RoundedToken, Eth } from "../../../assets";

import ActivityCard, { ActivityEnum } from "../../ActivityCard";
import PillFilter from "../../PillFilter";

const filters = ["Depositors", "Trades", "Vault fees", "Vault settings", "Protocol fees"]
const Activity = ({tokenName, tokenAddress}: {tokenName: string, tokenAddress: string}) => {
  const [filterList, setFilterList] = useState<string[]>([]);
  const filterChangeHandler = (filter: string) => {
    if(filterList.includes(filter)){
      setFilterList(filterList.filter((item) => item !== filter))
    }
    else{
      setFilterList([...filterList, filter])
    }
    console.log(filter)
  }
  return (
    <>
      <h2 className={styles.title}>Activity</h2>
      <div className={styles.pillFilter}>
        <div className={styles.filterTitle}>
          <span>Filters</span>
        </div>
        <div className={styles.filterButtons}>
          {filters.map((filter) => (
            <PillFilter
              key={filter}
              name={filter}
              isSelected={filterList.includes(filter)}
              filterClick={() => filterChangeHandler(filter)}
            />
          ))}

        </div>
      </div>

      <div className={styles.productContainer}>
        
            <ActivityCard 
              activityType={ActivityEnum.REDEMPTION}
              activityTime="26 May 2024 05:24"
              tokenName={tokenName}
              tokenAddress={tokenAddress}
              tokenImg={<RoundedToken />}
              rightSectionProps={{
                amountData: {
                  mainText: "899.8836 WETH",
                  subText: "$1,086,758.60",
                  tokenImg: <Eth />,
                },
                sharedRedeemedData:{
                  mainText: "924.7911",
                  tokenImg: <RoundedToken />,
                },
                depositorData: {
                  mainText: "0x4112784bda24cD141eaDe91a4DC09c263c4EF64B",
                },
              }}
            />
            <ActivityCard 
              activityType={ActivityEnum.VAULT_MIGRATION}
              activityTime="26 May 2024 05:24"
              tokenName={tokenName}
              tokenAddress={tokenAddress}
              tokenImg={<RoundedToken />}
            />
            <ActivityCard 
              activityType={ActivityEnum.ADD_ASSET}
              activityTime="26 May 2024 05:24"
              tokenName={tokenName}
              tokenAddress={tokenAddress}
              tokenImg={<RoundedToken />}
              rightSectionProps={{
                asset: {
                  mainText: "0 stETH",
                  subText: "$0.0",
                  tokenImg: <Eth />,
                },
              }}
            />
            <ActivityCard 
              activityType={ActivityEnum.SHARE_TRANSFER}
              activityTime="26 May 2024 05:24"
              tokenName={tokenName}
              tokenAddress={tokenAddress}
              tokenImg={<RoundedToken />}
              rightSectionProps={{
                shares: "5,555.0000",
                from: {
                  mainText: "0x4112784bda24cD141eaDe91a4DC09c263c4EF64B",
                },
                to: {
                  mainText: "0x4112784bda24cD141eaDe91a4DC09c263c4EF64B",
                },

              }}
            />
          
      </div>
    </>
  )

};

export default Activity;