import React, { ReactElement, useEffect, useState } from "react";
import DataTables, { Data, SelectedRow } from "../../components/DataTables";
import { defaultImg } from "../../assets";
import styles from "./index.module.scss";
import Dashboardtitle from "../../components/DashbordTitle";
import FlexBox from "../../components/common/FlexBox";

import { useNavigate } from "react-router";
import {
    useGetTransactionAlertsSumsubQuery
} from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { SortOrder, TableColumn } from "react-data-table-component";
import { setSearch, setSort } from "../../store/reducers/commonSlice";
import LoadingComponent from "../../components/LoadingComponent";
import Input from "../../components/Input";
import useWindowResize from "../../Hooks/useWindowResize";
import { sortCaseHandler } from "../../helper";
import { capitalizeText } from "../../utils/formatter";
import AlertMessage from "../../components/AlertMessage";
import { useAlertMessage } from "../../context/AlertContext";
import ReactSelect from "../../components/Select";
import { useSocket } from "../../context/Socket";
import { setTransactionAlerts } from "../../store/reducers/transactionAlertSlice";

export type Columns = {
    name: string;
    selector: (row: any) => string;
    cell?: (row: any) => string | ReactElement;
    sortable?: boolean;
    style?: {};
    sortField?: string;
    width?: string;
};

const TransactionAlertsSumSub = () => {
    const [selectedItem, setSelectedItem] = useState<SelectedRow | null>(null);
    const [clearAll, setClearAll] = useState<boolean>(false);

    const [page, setPage] = useState<number>(1);
    const { io } = useSocket();
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
        null
    );
    const { alertMsg, onCloseAlert } = useAlertMessage();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { search, sortValue } = useAppSelector((state) => state.common);

    const columns: Columns[] = [
        {
            name: "User",
            selector: (row) => row.user,
            cell: (row) => {
                return (
                    <div
                        className={styles.profilePicContainer}
                        onClick={() => {
                            dispatch(setTransactionAlerts(row));
                            navigate(`/user-management/${row.user.id}`);
                        }}
                    >
                        <img
                            src={row.user.image != "" ? row.user.image : defaultImg}
                            className={styles.profilePic}
                            alt="user name"
                        />
                        <div className={styles.profileNames}>
                            <h5>{row.user.firstName}</h5>
                            <p>{row.user.email}</p>
                        </div>
                    </div>
                );
            },
            sortable: true,
            sortField: "user",
        },
        {
            name: "ApplicantId",
            selector: (row) => row.user.applicantId,
            sortField: "applicantId",
            sortable: false,
        },
        {
            name: "Review Answer",
            selector: (row) => row.reviewAnswer,
            sortable: false,
            cell: (row) => <p className={styles.status}>{capitalizeText(row.reviewAnswer)}</p>,
            sortField: "reviewAnswer",
        },
        {
            name: "Review Reject Type",
            selector: (row) => row.reviewRejectType,
            sortable: false,
            cell: (row) => <p className={styles.balance}>{row.reviewRejectType}</p>,
            sortField: "reviewRejectType",
        },
        {
            name: "TransactionType",
            selector: (row) => row.reviewRejectType,
            sortable: false,
            cell: (row) => <p className={styles.balance}>{row.transactionType}</p>,
            sortField: "transactionType",
        },
        {
            name: "createdAt",
            selector: (row) => row.balance,
            sortable: true,
            cell: (row) => <p className={styles.balance}>{row.createdAt}</p>,
            sortField: "createdAt",
        },
    ];
    useEffect(() => {
        setPage(1);
    }, [search]);
    const { data, error, isFetching, isLoading, refetch } = useGetTransactionAlertsSumsubQuery({
        search,
        sorting: `${sortValue || 1}`,
        page: page + '',

    }, { refetchOnMountOrArgChange: true });
    console.log(data?.data);
    useEffect(() => {
        if (io) {
            io.on("sync-sumsub-txn", async (result: { userId: string }) => {
                refetch();
            });
        }
    }, [io, refetch]);
    if (error) {
        if ("status" in error && error?.status == 403) {
            navigate(`/unauthorized`);
        }
    }

    const getSelectedHandler = (selected: SelectedRow) => {
        setClearAll(false);
        setSelectedItem(selected);
    };

    useEffect(() => {
        clearAll && setClearAll(false);
    }, [clearAll]);


    const { width } = useWindowResize();
    const breakpoint = 575;

    const paginationHandler = (
        event: React.ChangeEvent<unknown>,
        page: number
    ) => {
        setClearAll(true);
        setSelectedItem(null)
        setPage(page);
    };
    const handleSort = async (
        selectedColumn: TableColumn<Data>,
        sortDirection: SortOrder,
        sortedRows: Data[]
    ) => {
        const sort = sortingFunction(selectedColumn?.sortField, sortDirection);
        dispatch(setSort({ sort: sort ?? 0 }));
    };
    const sortingFunction = (
        sortField: string | undefined,
        sortDirection: string
    ) => {
        switch (sortField) {
            case 'createdAt':
                return sortCaseHandler(sortDirection, 1, 2)
        }
    };
    if (isLoading) {
        return <LoadingComponent className="componentLoader" />;
    }
    const resetHandler = () => {
        setSelectedItem(null);
        setClearAll(true);
    };
    const debounce = (value: string) => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        const timeout = setTimeout(() => {
            dispatch(setSearch({ search: value }));
        }, 500);
        setDebounceTimeout(timeout);
    };
    const onChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        debounce(e.target.value);
    };

    return (
        <div className={styles.userManagementTable}>
            <div>

            <Dashboardtitle
                className={styles.management_Title}
                title="Transaction Alerts SumSub"
            />
            </div>

            {alertMsg?.msg && <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>{alertMsg.msg}</AlertMessage>}

            <FlexBox className={styles.flex}>
                <h3>Total alerts ({data?.data.count || 0
                })</h3>
                <div className={styles.right}>
                    <Input
                        type="text"
                        id="search"
                        placeholder="Search..."
                        name="search"
                        className={styles.searchInput}
                        onChange={onChangeHandler}
                    />

                </div>
            </FlexBox>

            <div className={styles.userManagementTable} data-id="alerts">
                <DataTables
                    columns={columns}
                    data={data?.data?.data ?? []}
                    count={data?.data.count}
                    selectableRows
                    onSelectedRowsChange={getSelectedHandler}
                    clearSelectedRows={clearAll}
                    handleSort={handleSort}
                    loading={isFetching}
                    entityName="alerts"
                    paginationHandler={paginationHandler}
                />
            </div>

        </div>
    );
};

export default TransactionAlertsSumSub;
