import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

export default function ProgressBar(
  props: LinearProgressProps & { value: number, parentStyle?: object}
) {
  return (
    <Box sx={{ display: "block", ...props.parentStyle }}>
      <Box
        sx={{
          textAlign: "left",
          fontWeight: "700",
          color: "#190044",
          fontFamily: 'Nunito Sans',
          marginBottom: "8px",
        }}
      >{`${Math.round(props.value)}` + "%"}</Box>
      <Box sx={{ width: "100%", mr: 1, textAlign: "left" }}>
        <LinearProgress
          sx={{
            height: "6px",
            backgroundColor: "#ECE8F2",
            borderRadius: "20px",
            ".MuiLinearProgress-bar": {
              backgroundColor: "#28255F",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  );
}

// export default function LinearWithValueLabel() {
//   const [progress, setProgress] = React.useState(10);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) =>
//         prevProgress >= 100 ? 10 : prevProgress + 10
//       );
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return (
//     <Box sx={{ width: "100%" }}>
//       <ProgressBar value={progress} />
//     </Box>
//   );
// }
