import styles from "./index.module.scss";
import Dashboardtitle from "../../components/DashbordTitle";
import CustomButton from "../../components/CustomButton";
import FlexBox from "../../components/common/FlexBox";
import IndicatorTokenCard from "../../components/IndicatorTokenCard/IndicatorTokenCard";
import { CardRecieve, SafeSquare, BankNote } from "../../assets";
import BuySellTokenCard from "../../components/BuySellTokenCard/BuySellTokenCard";
import { Link, useNavigate } from "react-router-dom";
import { useGetPlatformTokensQuery } from "../../service/api";


const IndicatorToken = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetPlatformTokensQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div>
      <div>
        <FlexBox className={styles.flex}>
          <Dashboardtitle title={"Indicator Tokens"} />
          <CustomButton className={styles.createTokenButton} onClick={() => { navigate("/vault") }}>
            Create New Token
          </CustomButton>
        </FlexBox>
        <FlexBox className={styles.flex}>
          <IndicatorTokenCard
            key={1}
            icon={<SafeSquare />}
            heading="Vaults"
            val={1391}
          />
          <IndicatorTokenCard
            key={1}
            icon={<BankNote />}
            heading="Assets Under Management"
            val={166121954.62}
            dollar
          />
          <IndicatorTokenCard
            key={1}
            icon={<CardRecieve />}
            heading="Deposits"
            val={6860}
          />
        </FlexBox>

        <div className={styles.indicatorTokenContent}>
          <p>
            As a novel approach for straightforward, easy investing, each one of
            our Indicator Tokens act as a basket containing carefully selected
            assets from a specific market. Buying one Indicator Token means
            buying a portion of all the assets it represents, which in turn
            means efficient portfolio diversification and higher potential
            returns!
          </p>
          <p>
            If you’re interested and would like to learn more, make sure to
            check out all our available ressources regarding our Indicator
            Tokens!
          </p>
        </div>
      </div>

      <div>
        <div>
          <Dashboardtitle
            title={"Buy & sell our Indicator Tokens"}
            description="See all the details about a Token’s performance, proof-of-reserve or simply buy or sell... This is where the magic happens!"
          />
          <div className={styles.BuySellTokenContainer}>
            {
              !isLoading && <>

                {data?.data?.map((item) => (
                  <div onClick={() => navigate(`/indicator-tokens/${item.symbol}/${item.id}`)}>
                    <BuySellTokenCard tokenName={item.name} tokenDescription={"It focuses on the 10 top trading assets on the NFT market."} />
                  </div>
                ))}

              </>
            }
          </div>
        </div>
        <div className={styles.quicklinks}>
          <Dashboardtitle title="Quick links" className={styles.quickTitle} />
          <Link className={styles.quicklink} to="/">
            Saving plans details
          </Link>
          <Link className={styles.quicklink} to="/">
            How to buy an Index Token?
          </Link>
        </div>
      </div>
    </div>
  );
};


export default IndicatorToken;
