import DataTable, { SortOrder, TableColumn, TableStyles } from "react-data-table-component";
import styles from "./index.module.scss";
import { SortIcon } from "../../assets";
import { Exapnded } from "../../types";
import { Pagination } from "@mui/material";
import LoadingComponent from "../LoadingComponent";

export type Data = {
  id: number | string | undefined ;
};

export type Data2 = {
  id: number | string | undefined;
  item1: string;
  item2: string;
  item3: string;
  item4: string;
  item5: string;
  item6: string;
  item7: string;
  item8: string;
};
interface Props {
  columns: TableColumn<Data | Data2>[];
  data: Data[];
  selectableRows?: boolean;
  onSelectedRowsChange?: (selected: SelectedRow) => void;
  clearSelectedRows?: boolean;
  handleSort?: (
    selectedColumn: TableColumn<Data>,
    sortDirection: SortOrder,
    sortedRows: Data[]
  ) => void;
  loading?: boolean;
  expandableRows?: boolean;
  expandedComponentData?: any;
  onRowExpandToggled?: (expander: boolean, row: Data) => void;
  isExpanded?: Exapnded;
  count?: number;
  entityName?: string;
  paginationHandler?: (event: React.ChangeEvent<unknown>, page: number) => void;
  customStyles?: TableStyles | undefined
  pagination?: boolean;
}
export type SelectedRow = {
  allSelected: boolean;
  selectedCount: number | undefined;
  selectedRows: Data[];
};
const defaultCustomStyles = {
  rows: {
    style: {
      minHeight: "72px",
      backgroundColor: "#fff",
      margin: "5px 0px",
      borderRadius: "10px",
      border: "0 !important",
      boxShadow: "none",
      padding: "10px 8px",
    },
  },
  rdt_TableHeadRow: {
    style: {
      borderBottom: 0,
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      background: "#f6f6f7",
      borderBottom: 0,
      fontWeight: 500,
      fontSize: 12,
      color: "#9599B5",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      borderBottom: 0,
    },
  },
  table: {
    style: {
      background: "#f6f6f6",
      borderBottom: 0,
    },
  },
};
const DataTables = (props: Props) => {
  const {
    columns,
    data,
    loading,
    onSelectedRowsChange,
    handleSort,
    expandableRows,
    expandedComponentData,
    clearSelectedRows,
    onRowExpandToggled,
    isExpanded,
    count,
    entityName,
    paginationHandler,
    pagination=false,
    customStyles,
    ...rest
  } = props;
  const totalNumberOfPages = count;
  const numberOfPages = Math.ceil(
    totalNumberOfPages && totalNumberOfPages > 10 ? totalNumberOfPages / 10 : 1
  );
  const expandableRowExpanded = (row: Data) => {
    if (isExpanded && row.id === isExpanded.id) {
      return !!isExpanded.status;
    }
    return false;
  }
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        sortIcon={<SortIcon />}
        responsive
        customStyles={customStyles ?? defaultCustomStyles}
        pagination={false}
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={onSelectedRowsChange}
        onSort={handleSort}
        progressPending={loading}
        progressComponent={<LoadingComponent className="componentLoader" />}
        expandableRows={expandableRows}
        expandableRowsComponent={expandedComponentData}
        onRowExpandToggled={onRowExpandToggled}
        expandableRowsHideExpander={true}
        expandableRowExpanded={expandableRowExpanded}
        {...rest}
      />
      {pagination && <div className={styles.pagination}>
      <p className={styles.result}>Showing {data.length} of {count} {entityName ? entityName: "items"}</p>
        <Pagination
          count={Math.ceil(numberOfPages)}
          color="primary"
          onChange={paginationHandler}
        />
      </div>}
    </div>
  );
};

export default DataTables;
